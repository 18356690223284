import React from "react";
import { Col, Row, Image, Container, Button } from '@themesberg/react-bootstrap';
import ErrorImage from "../../assets/img/illustrations/review.svg";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../routes";


export default () => {
  const handleLogout = () => {

    localStorage.removeItem('token');
    setTimeout(() => {
      return <Redirect to="/sign-in" />; // Redirect to login page after logout
    }, 1000); // Simulating a 1 second delay
  };


  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={5} className="order-2 order-lg-1 text-center text-lg-left">
              <h1 className="text-primary mt-5">
                Your account is under <span className="fw-bolder">review</span>.
          </h1>
              <p className="lead my-4">
              We’ll notify you on your whatsapp number once it’s approved.
          </p>
              <Button as={Link} variant="primary" size="sm" to={Routes.Signin.path}  className="me-2" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />Sign Out
          </Button>
            </Col>
            <Col xs={12} lg={7} className="order-1 order-lg-2 text-center d-flex align-items-center justify-content-center">
              <Image src={ErrorImage} className="img-fluid w-75" />
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
