import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Breadcrumb, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEdit, faSave, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from 'moment-timezone';
import Preloader from './Preloader';
import Datetime from 'react-datetime';
import { ChoosePhotoWidget, ProfileCardWidget } from './Widgets';
import VehicleComponent from './SubComponents/VehicleComponent';

const ProfilePage = ({ userData }) => {
  const [user, setUser] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
    contactNumber: '',
    address: '',
    joinDate: '',
    approvalStatus: '',
    driverLicence: '',
    validUntil: '',
    carNumber: '',
    carModel: '',
    licenseExpiryDate: ''
  });
  const [editMode, setEditMode] = useState(false);


  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/fetchDriverProfile?driverId=${userData.id}`);
      setUser(response.data); // Assuming response.data contains user details
    } catch (error) {
    }
  }, [userData]);

  useEffect(() => {
    fetchUserData();
  }, [userData]);

  useEffect(() => {
    if (user !== null) {
      setFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        birthDate: moment(user.birthDate).format('YYYY-MM-DD'),
        email: user.email,
        contactNumber: user.contactNumber,
        address: user.address,
        joinDate: moment(user.joiningDate).format('YYYY-MM-DD'),
        approvalStatus: user.approvalStatus || 'approved',
        driverLicence: user.driverLicence,
        licenseExpiryDate: user.licenseExpiryDate,
        validUntil: user.validUntil,
        carNumber: user.carNumber,
        carModel: user.carModel
      });
      setLastUpdated(user.updatedAt);
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (field) => (date) => {
    setFormData(prevState => ({ ...prevState, [field]: date.format('YYYY-MM-DD') }));
  };

  const changeLicenceExpiryDate = (field) => (date) => {
    setFormData(prevState => ({ ...prevState, [field]: date.format('YYYY-MM-DD') }));
  };

  const changeInsuranceExpiryDate = (field) => (date) => {
    setFormData(prevState => ({ ...prevState, [field]: date.format('YYYY-MM-DD') }));
  };

  const changePermitExpiryDate = (field) => (date) => {
    setFormData(prevState => ({ ...prevState, [field]: date.format('YYYY-MM-DD') }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put('https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/driver/update', formData);
      setUser(response.data); // Assuming response.data contains updated user details
      fetchUserData();
      setEditMode(false); // Exit edit mode after successful update
    } catch (error) {
      // Handle error state or show error message
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  if (!user) {
    return <><Preloader /></>; // You can customize loading indicator as per your design
  }

  return (
    <div>
      <Row className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-2 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Profile</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between align-items-center">
            <h4>My Profile
              <span className='f-12 mt-1 d-block text-gray fw-normal'>Last updated on {moment(lastUpdated).format('dddd')} {moment(lastUpdated).format('Do MMMM, YYYY')}</span>
            </h4>
            {!editMode && (
              <Button variant="white" className="text-dark me-2 dropdown-toggle " onClick={toggleEditMode}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
          </div>
        </div>
      </Row>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={8} className="mt-1">
          <Card className="mb-4">
              <Card.Header className='border-bottom'>
                <h5 className="mb-0">License details</h5>
              </Card.Header>
              <Card.Body>
                {/* <VehicleComponent driverId={userData.id} /> */}
                {!editMode ? (
                  <>
                    <Row className="mb-3">
                      <Col xs={6}>
                        <div id="drivingLicence">
                          <p className='mb-2 upperCase-keyword'><strong>License No.</strong></p>
                          <p className="text-muted mb-2 upperCase-keyword">{user.driverLicence}</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div id="validUntil">
                          <p className='mb-2 upperCase-keyword'><strong>Expiry Date</strong></p>
                          <p className="text-muted mb-2 upperCase-keyword">{moment(user.licenseExpiryDate).format('DD-MM-YYYY')}</p>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <div>
                    <Row className="mb-3">
                      <Col xs={12}>
                        <Form.Group id="drivingLicence">
                          <Form.Label>License No.</Form.Label>
                          <Form.Control
                            type="text"
                            name="driverLicence"
                            value={formData.driverLicence}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12}>
                        <Form.Group id="validUntil">
                          <Form.Label>Valid until</Form.Label>
                          <Datetime timeFormat={false} closeOnSelect onChange={changeLicenceExpiryDate('licenseExpiryDate')} value={formData.licenseExpiryDate}
                          renderInput={(props, openCalendar) => (
                            <InputGroup onClick={openCalendar}>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control readOnly required value={formData.licenseExpiryDate ? moment(formData.licenseExpiryDate).format('YYYY-MM-DD') : ''} />
                            </InputGroup>
                          )}
                        />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                )}
              </Card.Body>
            </Card>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Header className='border-bottom'>
                <h5 className="mb-0">Vehicle details</h5>
              </Card.Header>
              <Card.Body>
                {!editMode ? (
                  <>
                    <Row>
                      <Col xs={6} className="mb-3">
                        <div id="firstName">
                          <p className='mb-2 upperCase-keyword'><strong>Car Model</strong></p>
                          <p className="text-muted mb-2 upperCase-keyword">{user.carModel}</p>
                        </div>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div id="lastName">
                          <p className='mb-2 upperCase-keyword'><strong>Number Plate</strong></p>
                          <p className="text-muted mb-2 upperCase-keyword">{user.licensePlateNumber}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={6}>
                        <div id="birthday">
                          <p className='mb-2 upperCase-keyword'><strong>Seats</strong></p>
                          <p className="text-muted mb-2 upperCase-keyword">{user.seatCapacity}</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div id="email">
                          <p className='mb-2 upperCase-keyword'><strong>RC Number</strong></p>
                          <p className="text-muted mb-2 upperCase-keyword">{user.rcNumber}</p>
                        </div>
                      </Col>
                      </Row>
                      <Row className="mb-3">
                      <Col xs={6}>
                        <div id="phone">
                          <p className='mb-2 upperCase-keyword'><strong>Insurance Number</strong></p>
                          <p className="text-muted mb-2 upperCase-keyword">{user.insuranceNumber}</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div id="phone">
                          <p className='mb-2 upperCase-keyword'><strong>expiry date</strong></p>
                          <p className="text-muted mb-2 upperCase-keyword">{moment(user.permitExpiryDate).format('DD-MM-YYYY')}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={6}>
                        <div id="phone">
                          <p className='mb-2 upperCase-keyword'><strong>Permit Number</strong></p>
                          <p className="text-muted mb-2 upperCase-keyword">{user.permitNumber}</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div id="phone">
                          <p className='mb-2 upperCase-keyword'><strong>expiry date</strong></p>
                          <p className="text-muted mb-2 upperCase-keyword">{moment(user.permitExpiryDate).format('DD-MM-YYYY')}</p>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <div>
                    <Row>
                      <Col xs={12} className="mb-3">
                        <Form.Group id="carModel">
                          <Form.Label>Car Model</Form.Label>
                          <Form.Control
                            type="text"
                            name="carModel"
                            value={formData.carModel}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-3">
                        <Form.Group id="licensePlateNumber">
                          <Form.Label>Licence Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="licensePlateNumber"
                            value={formData.licensePlateNumber}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12}>
                        <Form.Group id="seatCapacity">
                          <Form.Label>Seat Capacity</Form.Label>
                          <Form.Control
                            type="number"
                            name="seatCapacity"
                            value={formData.seatCapacity}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} >
                        <Form.Group id="rcNumber">
                          <Form.Label>Registration Certificate</Form.Label>
                          <Form.Control
                            type="text"
                            name="rcNumber"
                            value={formData.rcNumber}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12}>
                        <Form.Group id="insuranceNumber">
                          <Form.Label>Insurance</Form.Label>
                          <Form.Control
                            type="text"
                            name="insuranceNumber"
                            value={formData.insuranceNumber}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Form.Group id="insuranceExpiryDate">
                          <Form.Label>Expiry Date</Form.Label>
                          <Datetime timeFormat={false} closeOnSelect onChange={changeInsuranceExpiryDate('insuranceExpiryDate')} value={formData.insuranceExpiryDate}
                          renderInput={(props, openCalendar) => (
                            <InputGroup onClick={openCalendar}>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control readOnly required value={formData.insuranceExpiryDate ? moment(formData.insuranceExpiryDate).format('YYYY-MM-DD') : ''} />
                            </InputGroup>
                          )}
                        />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Form.Group id="permitNumber">
                          <Form.Label>Permit Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="permitNumber"
                            value={formData.permitNumber}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Form.Group id="permitExpiryDate">
                          <Form.Label>Expiry Date</Form.Label>
                          <Datetime timeFormat={false} closeOnSelect onChange={changePermitExpiryDate('permitExpiryDate')} value={formData.permitExpiryDate}
                            renderInput={(props, openCalendar) => (
                              <InputGroup onClick={openCalendar}>
                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                <Form.Control readOnly required value={formData.permitExpiryDate ? moment(formData.permitExpiryDate).format('YYYY-MM-DD') : ''} />
                              </InputGroup>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={4} className="mt-1">
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Header className='border-bottom'>
                <h5 className="mb-0">Personal details</h5>
              </Card.Header>
              <Card.Body>
              {!editMode ? <>
                  <ProfileCardWidget photo={'https://transferappdata.s3.ap-southeast-1.amazonaws.com/user+(2).png'} userName={`${user.firstName} ${user.lastName}`} contactNo={user.contactNumber} email={user.email} />
                  <div className="mb-3">
                    <div id="joinDate">
                      <p className='mb-2 upperCase-keyword'><strong>Date of birth</strong></p>
                      <p className="text-muted mb-2 upperCase-keyword">{moment(user.birthDate).format("DD-MM-YYYY")}</p>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div id="approvalStatus">
                      <p className='mb-2 upperCase-keyword'><strong>Address</strong></p>
                      <p className="text-muted mb-2 upperCase-keyword">{user.address}</p>
                    </div>
                  </div>
                </>
            : (
              <div>
                <Row>
                  <Col xs={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                <Col xs={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={12}>
                    <Form.Group id="contactNumber">
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={12} >
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={12}>
                    <Form.Group id="birthDate">
                      <Form.Label>Date of birth</Form.Label>
                      <Datetime timeFormat={false} closeOnSelect onChange={handleDateChange('birthdate')} value={formData.permitExpiryDate}
                          renderInput={(props, openCalendar) => (
                            <InputGroup onClick={openCalendar}>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control readOnly required value={formData.birthDate ? moment(formData.birthDate).format('YYYY-MM-DD') : ''} />
                            </InputGroup>
                          )}
                        />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Form.Group id="address">
                      <Form.Label>Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="address"
                          value={formData.address}
                          onChange={handleInputChange}
                          required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            )}
            </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>

      {editMode && (
        <Row>
          <Col xs={12} className="mb-4">
            <div className='d-flex justify-content-between'>
              <Button variant="primary multi-button-display" onClick={handleSubmit}>
                <FontAwesomeIcon icon={faSave} className="me-2" /> Save
              </Button>
              <Button variant="outline-danger multi-button-display" onClick={toggleEditMode}>
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProfilePage;
