// RoutesConfig.js
import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// Pages
import Signin from "./examples/Signin";
import Signup from './examples/Signup';

import Profile from './Profile';

import DriverDetailsPage from './examples/DriversDetailsPage';

// Components
import WithAuth from '../components/Authentication/WithAuth';
import RouteWithLoader from '../RoutesWithLoader';
import RouteWithSidebar from '../RoutesWithSidebar';
import CabDriverUpcomingTours from './examples/CabDriver/CabDriverUpcomingTours';
import TourHistory from './examples/CabDriver/TourHistory/TourHistory';
import DashboardOverview from './dashboard/DashboardOverview';
import TripDetails from './components/TripDetails';
import PostTripDetails from './components/PostTripDetails';
import Wallet from './Wallet';
import DriverSignUpPage from './examples/DriverSignUpPage';
import AdminConfirmation from './examples/AdminConfirmation';
import Transactions from './Transactions';
import VehicleRegistrationPage from './examples/VehicleRegistration';


const RoutesConfig = () => (
  <>
    <Switch>
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.Signup.path} component={DriverSignUpPage} />
      <RouteWithSidebar exact path={Routes.VehicleRegistration.path} component={VehicleRegistrationPage} />
      <RouteWithLoader exact path={Routes.AdminConfirmation.path} component={AdminConfirmation} />
      <RouteWithSidebar exact path="/trip-details/:bookingId/:id" component={WithAuth(TripDetails)} />
      <RouteWithSidebar exact path="/trip-end-details/:bookingId/:id" component={WithAuth(PostTripDetails)} />
      <RouteWithSidebar exact path="/tours-history" component={WithAuth(TourHistory)} />
      <RouteWithSidebar exact path={Routes.Transaction.path} component={WithAuth(Transactions)} />

      {/* Driver routes */}
      <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={WithAuth(DashboardOverview)} />
      <RouteWithSidebar exact path={Routes.Profile.path} component={WithAuth(Profile)} />
      <RouteWithSidebar exact path={Routes.Wallet.path} component={WithAuth(Wallet)} />
      <RouteWithSidebar exact path={Routes.UpcomingTours.path} component={WithAuth(CabDriverUpcomingTours)} />
      <RouteWithSidebar exact path={Routes.DriverDetails.path} component={WithAuth(DriverDetailsPage)} />
      <RouteWithSidebar exact path={Routes.ToursHistory.path} component={WithAuth(TourHistory)} />
      <Redirect to={Routes.NotFound.path} />
    </Switch>
  </>
);

export default RoutesConfig;
