import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import axios from 'axios';
import LoaderComponent from '../components/LoaderComponent';

export default ({ data }) => {
    const [walletData, setWalletData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const fetchWalletData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/walletDetails?driverId=${data.id}`)
            setWalletData(response.data);
        }
        catch (error) { }
        finally {
            setIsLoading(false);
        }
    }, [data]);

    useEffect(() => {
        fetchWalletData();
    }, [data]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!walletData || parseFloat(walletData.balance) === 0) {
            return; // Prevent submission if balance is zero
        }
        try {
            await axios.post('https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/withdraw', {
                amount: walletData.balance,
                driverId: data.id
            });
            fetchWalletData()
        } catch (error) {
            console.error(error)
        }
    };

    const isDisabled = !walletData || parseFloat(walletData.balance) <= 0;

    return (
        <Row>
            <Col xs={12} md={12}>
                {/* Wallet Details Card */}
                <Card border="light" className="bg-white shadow-sm">
                    {isLoading
                        ? <LoaderComponent />
                        : <>
                            <Card.Header className="border-bottom">
                                <h5 className="mb-0"><FontAwesomeIcon icon={faWallet} className="me-2" /> My Wallet</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row className="mb-3">
                                    <Col xs={6} md={6}>
                                        <p className="mb-1 text-muted">Current Balance</p>
                                        <h4 className="mb-0">RM {parseInt(walletData.balance).toFixed(2)}</h4>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <div className='mb-2 d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center pt-4'>
                                            <Button variant="primary" size="sm" className="m-1 upperCase-keyword text-dark me-2 dropdown-toggle btn btn-secondary animate-up-2" onClick={handleSubmit} disabled={isDisabled}>
                                                Withdraw
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <span className='f-12'>Last updated on {moment(walletData.updatedAt).format('dddd')} {moment(walletData.updatedAt).format('Do MMMM, YYYY')}</span>
                            </Card.Body>
                        </>
                    }
                </Card>
            </Col>
        </Row>
    );
};
