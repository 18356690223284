import React from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';
import WalletCard from './examples/WalletCard';
import TransactionCard from './examples/TransactionCard';
import ExpensesCard from './examples/ExpensesCard';
import MiniAnalyticsCards from './examples/MiniAnalyticsCards';

const Wallet = ({ data }) => {
  return (
    <Row>
      <Col xs={12} md={12}>
        {/* Wallet Details Card */}
          <WalletCard data={data} />
        {/* Tiles cards */}
          {/* <MiniAnalyticsCards data={data} /> */}
        {/* Tracking History Card */}
          <TransactionCard data={data} />
        {/* Expense */}
          <ExpensesCard data={data} />
      </Col>
    </Row>
  );
};

export default Wallet;
