import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import axios from 'axios';
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap';
import ShortenAddress from '../../components/Utility/ShortenAddress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

export default (data) => {
    console.log(data)
    const [expenses, setExpenses] = useState();
    useEffect(() => {
        const fetchExpenses = async () => {
            try {
                const response = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/expenses/driverView?driverId=${data.driverId}`);
                const expData = response.data;
                const groupedExpenses = expData.reduce((acc, expense) => {
                    const { tourId, driverName, customerName, tourCompletionDate, startLocation, endLocation, pax, tripStartDate, tourNameList, status, amount } = expense;

                    if (!acc[tourId]) {
                        acc[tourId] = {
                            tourId,
                            driverName,
                            customerName,
                            tourCompletionDate,
                            startLocation,
                            endLocation,
                            pax,
                            tripStartDate,
                            tourNameList,
                            totalApproved: 0,
                            totalRejected: 0,
                            expenses: [] // Initialize an array for expenses
                        };
                    }

                    // Convert amount to a number and add to totals
                    if (status === "approved") {
                        acc[tourId].totalApproved += parseFloat(amount);
                    } else if (status === "rejected") {
                        acc[tourId].totalRejected += parseFloat(amount);
                    }

                    acc[tourId].expenses.push({
                        expenseId: expense.expenseId,
                        expenseType: expense.expenseType,
                        amount,
                        description: expense.description,
                        createdAt: expense.createdAt,
                        status
                    });

                    return acc;
                }, {});


                // Convert the grouped object into an array
                const result = Object.values(groupedExpenses);
                const updatedData = result.filter(item => item.tourId === parseInt(data.tourId))
                // Check if expenses is present and modify it
                if (updatedData[0].expenses) {
                    const approvedExpenses = updatedData[0].expenses
                    .filter(expense => expense.status === "approved")
                    .reduce((acc, expense) => {
                        const existing = acc.find(item => item.expenseType === expense.expenseType);
                        if (existing) {
                            existing.amount += parseFloat(expense.amount);
                        } else {
                            acc.push({
                                expenseType: expense.expenseType,
                                amount: parseFloat(expense.amount)
                            });
                        }
                        return acc;
                    }, []);
                    updatedData[0].expenses = approvedExpenses;
                }
                setExpenses(updatedData)
                

            } catch (error) {
                console.error('Error fetching tours:', error);
            }
        };

        fetchExpenses();
    }, [data]);

    return (
        <>
            {expenses && expenses.map((expense) => (
                expense.totalApproved > 0 && (  // Check if totalApproved is greater than 0
                    <>
                                {expense.expenses.map(item => (
                                    <div className="mb-2 d-flex justify-content-between">
                                        <span className="fs-18 upperCase-keyword">{item.expenseType}</span>
                                        <span className="fs-18">RM {item.amount}</span>
                                    </div>
                                ))}
                    </>
                )
            ))}
        </>
    );
};
