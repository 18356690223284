import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClock, faHome, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Breadcrumb, Badge } from '@themesberg/react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import axios from 'axios';
import TripInformation from "../subComponents/TripInformation";
import moment from "moment-timezone";
import ShowRating from "../../components/Utility/ShowRating";

const TripDetails = ({data}) => {
    const [tripData, setTripData] = useState(null);
    const { bookingId, id } = useParams();
    const history = useHistory(); // Access to the history object

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/tripDetails?tourId=${id}&bookingId=${bookingId}`);
                setTripData(response.data[0]); // Assuming response.data contains trip details
            } catch (error) {
            }
        };
        
        fetchUserData();
    }, [id, bookingId]);

    return (
        <>
            {tripData && (
                <>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-md-0">
                        <div className="mb-4">
                            <p onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
                                <FontAwesomeIcon icon={faArrowLeft} size="md" />
                            </p>
                        </div>
                        <h4>Trip details</h4>
                        <p className="mb-0">{tripData.tourname}</p>
                        </div>
                    </div>
                </>
            )}
            <Row className="d-flex justify-content-center">
                <Col xl={12}>
                    <Card border="light" className="shadow-sm mb-6">
                        {tripData && (
                            <>
                                <Card.Header>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h6 >Start Date & Time</h6>
                                            <div className="d-flex justify-content-start mt-2">
                                                <FontAwesomeIcon icon={faClock} className="progress-label text-danger mt-1" />
                                                <div className="px-2">
                                                    <p className="mb-0">
                                                    {moment(tripData.tourDate).format('D MMM, YYYY')} {moment(tripData.tourTime, 'HH:mm').format('hh:mm A')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <Badge bg={`${tripData.status === 'pending' ? 'warning' : 'success'}`} variant="primary" size="sm" className="card-subtitle badge-md px-2 upperCase-keyword fw-regular">{tripData.status}</Badge>
                                        </div>
                                    </div>
                                    {tripData.status === 'completed' ? 
                                    <>
                                    <div className="mb-0 mt-4">
                                        <h6>Trip End Date & Time</h6>
                                        <div className="d-flex justify-content-start mt-2">
                                            <FontAwesomeIcon icon={faClock} className="progress-label text-danger mt-1" />
                                            <div className="px-2">
                                                <p>
                                                {moment(tripData.completedAt).format('D MMM, YYYY')} {moment(tripData.completedAt, 'HH:mm').format('hh:mm A')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-0">
                                        <h6>Rating</h6>
                                        <div className="d-flex justify-content-start mt-2">
                                            <ShowRating rating={tripData.ratingScore} />
                                        </div>
                                    </div>
                                    </>
                                    :<></>}
                                </Card.Header>
                                {TripInformation({...tripData, driverId: data.id, tourId: bookingId})}
                            </>
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default TripDetails;
