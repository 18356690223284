
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarAlt, faSignOutAlt, faTimes, faUserEdit, faLocationArrow, faWallet, faTachometerAlt, faFileInvoice, faMedal } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Navbar, Card, ProgressBar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import TvgLogo from "../assets/img/brand/TV-Logo.png";
import ProfilePicture from "../assets/img/team/driver.png";
import { Redirect } from 'react-router-dom';
import NotificationsWrapper from "./widgets/NotificationsWrapper";
import { IoSwapHorizontal } from "react-icons/io5";
import AvailabilitySwitch from "./SubComponents/AvailabilitySwitch";

export default ({ data }) => {
  const [driverAvailability, setDriverAvailability] = useState(true);

  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon">{icon}</span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  const handleLogout = () => {

    localStorage.removeItem('token');
    setTimeout(() => {
      return <Redirect to="/sign-in" />; // Redirect to login page after logout
    }, 1000); // Simulating a 1 second delay
  };

  const handleAvailabilityChange = (isAvailable) => {
    setDriverAvailability(isAvailable);
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="px-3 d-md-none bg-white shadow-sm-custom" fixed="top">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src={TvgLogo} className="navbar-brand-light" />
        </Navbar.Brand>
        <AvailabilitySwitch driverId={data.id} onAvailabilityChange={handleAvailabilityChange} />
        <div className={`driverAvailability ${!driverAvailability ? '' : 'd-none'}`}>
        <p className="mb-0">{!driverAvailability && 'You are offline'}</p>
      </div>
      </Navbar>
    </>
  );
};
