import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    Form,
    InputGroup,
    Spinner,
    Image,
    Container,
    Row,
    Col,
} from '@themesberg/react-bootstrap';
import {
    faCalendarAlt,
    faCar,
    faClipboardCheck,
    faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import TvgLogo from '../../assets/img/brand/TV-Logo.png';

export default ({ driverData }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        licensePlateNumber: '',
        seatCapacity: '',
        id: '',
        rc: '',
        insurance: '',
        permit: '',
        permitDate: '',
        insuranceDate: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.licensePlateNumber) newErrors.licensePlateNumber = 'License Plate Number is required';
        if (!formData.seatCapacity) newErrors.seatCapacity = 'Seat Capacity is required';
        if (formData.rc && !/^RC[0-9]+$/.test(formData.rc)) newErrors.rc = 'Invalid RC number format';
        if (!formData.insurance) newErrors.insurance = 'Insurance number is required';
        if (!formData.insuranceDate) newErrors.insuranceDate = 'Insurance expiry date is required';
        if (!formData.permit) newErrors.permit = 'Permit is required';
        if (!formData.permitDate) newErrors.permitDate = 'Permit expiry date is required';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});

        const newErrors = validate();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        const payload = {
            licensePlateNumber: formData.licensePlateNumber,
            seatCapacity: parseInt(formData.seatCapacity),
            id: formData.id,
            createdBy: '',
            rcNumber: formData.rc,
            insuranceNumber: formData.insurance,
            permitNumber: formData.permit,
            licenseExpiryDate: moment(formData.insuranceDate).format('YYYY-MM-DD'),
            permitExpiryDate: moment(formData.permitDate).format('YYYY-MM-DD'),
        };
        

        try {
            const response = await axios.post('https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/signup', { ...formData });
            if (response.status >= 200 && response.status < 300) {
                localStorage.setItem('token', response.data.token);
            } else {
                setFormData({ ...formData, error: 'Failed to signup.', loading: false });
                // scrollToMessageAfterSubmitForm();
            }

            await axios.post('https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/vehicle/addVehicle', payload);
            toast.success('Form submitted successfully');
        } catch (error) {
            toast.error('Error submitting the form');
        } finally {
            setLoading(false);
        }
    };

    const yesterday = moment().subtract(1, 'day');
    const valid = current => current.isAfter(yesterday);

    return (
        <>
            <main>
                <section className="d-flex my-5 mt-lg-6 mb-lg-5">
                    <Container>
                        <Row className="justify-content-center form-bg-image">
                            <Col xs={12} className="d-flex align-items-center justify-content-center">
                                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-800">
                                    <div className="text-center mb-2">
                                        <Image src={TvgLogo} className="w-48Percent" />
                                    </div>
                                    <h3 className="text-center mb-4">Create an account</h3>
                                    <Toaster />
                                    <Form className="form-withvalidation" onSubmit={handleSubmit}>
                                    <Form.Group controlId="formCarModel" className='mb-1'>
                                            <Form.Label column>Car Model</Form.Label>
                                            <InputGroup className='d-flex'>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faCar} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="carModel"
                                                    placeholder="Enter Model Name"
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.carModel}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.carModel}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group controlId="formLicensePlateNumber" className='mb-1'>
                                            <Form.Label column>License Plate Number</Form.Label>
                                            <InputGroup className='d-flex'>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faCar} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="licensePlateNumber"
                                                    placeholder="Enter License Plate Number"
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.licensePlateNumber}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.licensePlateNumber}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group controlId="formSeatCapacity" className='mb-1'>
                                            <Form.Label column>Seat Capacity</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faClipboardCheck} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="number"
                                                    name="seatCapacity"
                                                    placeholder="Enter number of passengers"
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.seatCapacity}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.seatCapacity}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group controlId="formRC" className='mb-1'>
                                            <Form.Label column>Registration Certificate (RC)</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="rc"
                                                    placeholder='Enter RC number'
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.rc}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.rc}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group controlId="formInsurance" className='mb-1'>
                                            <Form.Label column>Vehicle Insurance</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="insurance"
                                                    placeholder="Upload Insurance file"
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.insurance}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.insurance}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group controlId="formInsuranceDate" className='mb-1'>
                                            <Form.Label>Vehicle Insurance Date</Form.Label>
                                            <Datetime
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                onChange={date => setFormData({ ...formData, insuranceDate: date })}
                                                isValidDate={valid}
                                                inputProps={{
                                                    name: 'insuranceDate',
                                                    required: true,
                                                    placeholder: 'mm/dd/yyyy',
                                                }}
                                                renderInput={(props, openCalendar) => (
                                                    <InputGroup onClick={openCalendar}>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            value={formData.insuranceDate ? moment(formData.insuranceDate).format('YYYY-MM-DD') : ''}
                                                            placeholder='Select date'
                                                            required
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                            {errors.insuranceDate && (
                                                <Form.Text className="text-danger">{errors.insuranceDate}</Form.Text>
                                            )}
                                        </Form.Group>

                                        <Form.Group controlId="formPermit" className='mb-1'>
                                            <Form.Label column>Vehicle Permit</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="permit"
                                                    placeholder="Enter Permit"
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.permit}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.permit}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group controlId="formPermitDate" className='mb-1'>
                                            <Form.Label>Vehicle Permit Date</Form.Label>
                                            <Datetime
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                onChange={date => setFormData({ ...formData, permitDate: date })}
                                                isValidDate={valid}
                                                inputProps={{
                                                    name: 'permitDate',
                                                    required: true,
                                                    placeholder: 'mm/dd/yyyy',
                                                }}
                                                renderInput={(props, openCalendar) => (
                                                    <InputGroup onClick={openCalendar}>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            value={formData.permitDate ? moment(formData.permitDate).format('YYYY-MM-DD') : ''}
                                                            placeholder='Select date'
                                                            required
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                            {errors.permitDate && (
                                                <Form.Text className="text-danger">{errors.permitDate}</Form.Text>
                                            )}
                                        </Form.Group>

                                        <Button variant="primary" className="animate-up-2" type="submit" disabled={loading}>
                                            {loading ? (
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    <span className="ms-2">Loading...</span>
                                                </>
                                            ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        </>
    );
};
