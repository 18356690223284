import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import axios from 'axios';

export default ({ data }) => {
    const [walletData, setWalletData] = useState();

    useEffect(() => {
        axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/walletDetails?driverId=${data.id}`)
            .then(response => {
                setWalletData(response.data);
            })
            .catch(error => {
                console.error('Error fetching wallet data:', error);
            });
    }, [data]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!walletData || parseFloat(walletData.balance) === 0) {
            return; // Prevent submission if balance is zero
        }
        try {
            await axios.post('https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/withdraw', {
                amount: walletData.balance,
                driverId: data.id
            });
        } catch (error) {
            console.error(error)
        }
    };

    const isDisabled = !walletData || parseFloat(walletData.balance) <= 0;

    const withdrawlDays = () => {

        // Get the current date with time set to 00:00:00
        const today = moment().startOf('day');

        // Get the last day of the current month with time set to 00:00:00
        const endOfMonth = moment().endOf('month').startOf('day');

        // Calculate the number of days left
        const daysLeft = endOfMonth.diff(today, 'days');

        return daysLeft;

    }

    withdrawlDays();

    return (
        <Row>
            <Col xs={12} md={12}>
                {/* Wallet Details Card */}
                <Card border="light" className="bg-white">
                    {walletData && (
                        <>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <div>
                                                <p className="mb-1 text-muted f-12">Current Balance</p>
                                                <h4 className="mb-0 f-16">RM {parseInt(walletData.balance).toFixed(2)}</h4>
                                            </div>
                                            <div>
                                                <Button variant="primary" size="sm" className="m-1 upperCase-keyword text-dark me-2 dropdown-toggle btn btn-secondary animate-up-2" onClick={handleSubmit} disabled={isDisabled}>
                                                    Withdraw
                                                </Button>
                                            </div>
                                        </div>
                                        <p className='mb-0 f-10'>You must withdraw the amount within {withdrawlDays()} days</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </>
                    )}
                </Card>
            </Col>
        </Row>
    );
};
