import { Form } from '@themesberg/react-bootstrap';
import React from 'react';

export default() => {
    return (
            <div className='d-flex align-items-center justify-content-start'>
                <Form.Select
                    defaultValue="0"
                    className='f-12'
                >
                    <option value="">Sort By:</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="documentation_required">Documentation Required</option>
                    <option value="maintenance">Maintenance</option>
                    <option value="terminated">Terminated</option>
                    
                </Form.Select>

            </div>
    )
}