import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Modal } from '@themesberg/react-bootstrap';

const Availability = ({ driverId, onAvailabilityChange }) => {
    const [isAvailable, setIsAvailable] = useState(true);
    const [leaveReason, setLeaveReason] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const checkAvailability = async () => {
            try {
                const { data } = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/driver/${driverId}/attendance`);

                console.log(data)
                if (data.length > 0) {
                    const isAvailable = data[0].isAvailable;
                    setIsAvailable(isAvailable);
                    onAvailabilityChange(isAvailable);
                }
            } catch (error) {
                console.error('Error fetching availability:', error);
            }
        };

        checkAvailability();
    }, [driverId]);

    const handleSubmit = async (availability = isAvailable, reason = leaveReason) => {
        try {
            await axios.post(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/driver/${driverId}/attendance`, {
                driverId,
                isAvailable: availability,
                leaveReason: availability ? null : reason,
            });
            onAvailabilityChange(availability);
        } catch (error) { }
    };

    const handleSwitchChange = () => {
        if (isAvailable) {
            setShowModal(true);
        } else {
            resetAvailability();
        }
    };

    const resetAvailability = () => {
        setIsAvailable(true);
        setLeaveReason('');
        handleSubmit(true);
        onAvailabilityChange(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleConfirmLeaveReason = () => {
        if (leaveReason) {
            setIsAvailable(false);
            setShowModal(false);
            handleSubmitWithDelay(false, leaveReason);
        }
    };

    const handleSubmitWithDelay = (availability, reason) => {
        // Use setTimeout to ensure state has updated
        setTimeout(() => {
            handleSubmit(availability, reason);
        }, 0);
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="availabilitySwitch" className="d-flex align-items-center">
                    <Form.Check
                        type="switch"
                        id="availabilitySwitch"
                        checked={isAvailable}
                        onChange={handleSwitchChange}
                        className="mx-2"
                    />
                </Form.Group>

                {/* Modal for Leave Reason Confirmation */}
                <Modal show={showModal} centered size="sm" onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Leave Reason</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="modalLeaveReason">
                            <Form.Label>Select Leave Reason:</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={(e) => setLeaveReason(e.target.value)}
                                value={leaveReason}
                            >
                                <option value="">Select reason</option>
                                <option value="Personal">Personal</option>
                                <option value="Sick">Sick</option>
                                <option value="Vacation">Vacation</option>
                                <option value="Other">Other</option>
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleConfirmLeaveReason} disabled={!leaveReason}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        </>
    );
};

export default Availability;
