import React from 'react';
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faClock } from '@fortawesome/free-solid-svg-icons';
import TransactionCard from '../subComponents/TransactionCard';
import { Link } from 'react-router-dom';

export default ({ data }) => {
  const [transactionCounts, setTransactionCounts] = React.useState({
    total: 0,
    withdrawal: 0,
    rewards: 0,
    currentBalance: 0,
    withdrawalBalance: 0,
    rewardBalance: 0
});

const handleTransactionData = (counts) => {
    setTransactionCounts(counts);
};

  return (
    <Row className=' mt-4 mb-4'>
      <Col xs={12} md={12}>
        <Card className="bg-transparent mb-3 border-0">
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className="mb-0">
                <FontAwesomeIcon icon={faClock} className="me-2" /> Transactions History
            </h5>
            <Link to="/transactions">
                  <Button variant="outline-primary" size="sm" className="m-1 upperCase-keyword f-10">
                     <span className='space-after'>See All </span> <FontAwesomeIcon icon={faAngleRight} />
                  </Button>
                </Link>
            </div>
        </Card>
        < TransactionCard data={data} limit={5} handleTransactionData={handleTransactionData} />
      </Col>
    </Row>
  );
};
