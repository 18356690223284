import { Form } from '@themesberg/react-bootstrap';
import React from 'react';

export default() => {
    return (
            <div className='d-flex align-items-center justify-content-start'>
                <Form.Select
                    defaultValue="0"
                    className='f-12'
                >
                    <option value="">All</option>
                    <option value="rewards">Rewards</option>
                    <option value="withdrawl">Withdrawal</option>
                    <option value="expenses">Expenses</option>
                </Form.Select>

            </div>
    )
}