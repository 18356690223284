// components/Authentication/WithAuth.js
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import Preloader from '../Preloader'; // Assuming you have a Preloader component
import SidebarDriver from '../SidebarDriver';
import Achievements from '../../pages/examples/widget/Achievements';
import NewTripNotification from './NewTripNotification';
import { Routes } from '../../routes';
import LiveLocation from '../Utility/LiveLocation';
import Footer from '../Footer';

export default function withAuth(ComponentInside) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        redirect: false,
        userData: {
          id: '',
          email: '',
          username: '',
          role: '',  // 'admin' or 'driver'
        },
        tripAssigned: false, // Track if a trip has been assigned,
        newTasks: [],
        achievementData: [],
        location: null
      }
    }

    componentDidMount() {
      this.verifyToken();
    }

    handleTripAssignedAction = () => {
      this.setState({ tripAssigned: true });
    };

    verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        this.setState({ loading: false, redirect: true });
        return;
      }

      try {
        const response = await axios.post('https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/token', { token });
        if (response.status === 200) {
          const { email, userRole } = response.data;
          this.fetchUserData(email, userRole);
        } else {
          throw new Error('Token verification failed');
        }
      } catch (error) {
        this.setState({ loading: false, redirect: true });
      }
    };

    sendLocationToServer = async (latitude, longitude, driverId) => {
      try {
        await axios.post('https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/location', {
          latitude,
          longitude,
          driverId
        });
        await LiveLocation(driverId)
      } catch (error) {
      }
    };

    getLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            this.setState({ location: { latitude, longitude } });
            const driverId = this.state.userData.id;
            await this.sendLocationToServer(latitude, longitude, driverId);
          },
          (err) => {
          }
        );
      } else {
      }
    };

    fetchUserData = async (email, userRole) => {
      try {
        const userDataResponse = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/loginDetails?email=${email}&userRole=${userRole}`);
        this.getLocation();
        const { driverId, firstName, lastName, email: emailAddress, approvedStatus } = userDataResponse.data;
        this.setState({
          loading: false,
          userData: {
            id: driverId,
            email: emailAddress,
            username: `${firstName} ${lastName}`,
            role: userRole,  // Assume role is 'admin' or 'driver'
          },
          adminConfirmation: approvedStatus
        });
        this.fetchAchievements(driverId)
        // this.fetchNewTasks(driverId);
      } catch (error) {
        this.setState({ loading: false, redirect: true });
      }
    };


    fetchAchievements = async (driverId) => {
      try {
        const response = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/driver/${driverId}/achievements`);
        this.setState({ achievementData: response.data });
      } catch (err) {
      }
    }

    handleTripAssignedAction = () => {
      this.setState({ tripAssigned: true }, () => {
        window.location.reload(); // Refresh the page after accepting or declining the trip
      });
    };

    render() {
      const { loading, redirect, userData, tripAssigned, newTasks, adminConfirmation, achievementData } = this.state;

      if (loading) {
        return <Preloader show={true} />; // Show Preloader while loading
      }

      if (redirect) {
        return <Redirect to="/sign-in" />;
      }

      if (!adminConfirmation) {
        return <Redirect to={Routes.AdminConfirmation.path} />
      }


      return (
        <>
          <div>
            {/* <Achievements data={achievementData} /> */}
            <SidebarDriver data={{ ...userData, ...achievementData }} />
            <NewTripNotification data={userData} handleTripAssignedAction={this.handleTripAssignedAction} />
            <main className="content py-6">
              <Navbar data={{ ...userData, ...achievementData }} isAdmin={userData.role === 'driver'} />
              <ComponentInside {...this.props} data={userData} isAdmin={false} />
            </main>
            <Footer data={{ ...userData, ...achievementData }} />
          </div>
        </>
      );
    }
  };
}
