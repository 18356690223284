
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarAlt, faSignOutAlt, faTimes, faUserEdit, faLocationArrow, faWallet, faTachometerAlt, faFileInvoice, faMedal, faHome } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Navbar, Card, ProgressBar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import TvgLogo from "../assets/img/brand/TV-Logo.png";
import ProfilePicture from "../assets/img/team/driver.png";
import { Redirect } from 'react-router-dom';
import NotificationsWrapper from "./widgets/NotificationsWrapper";
import { IoSwapHorizontal } from "react-icons/io5";
import AvailabilitySwitch from "./SubComponents/AvailabilitySwitch";

export default ({ data }) => {
    const location = useLocation();
    const { pathname } = location;
    const [show, setShow] = useState(false);
    const showClass = show ? "show" : "";

    const onCollapse = () => setShow(!show);

    const NavItem = (props) => {
        const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
        const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
        const navItemClassName = link === pathname ? "active" : "";
        const linkProps = external ? { href: link } : { as: Link, to: link };

        return (
            <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
                <Nav.Link {...linkProps} target={target} className={classNames}>
                    <span>
                        {icon ? <span className="sidebar-icon">{icon}</span> : null}
                        {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

                        <span className="sidebar-text">{title}</span>
                    </span>
                    {badgeText ? (
                        <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        );
    };

    const handleLogout = () => {

        localStorage.removeItem('token');
        setTimeout(() => {
            return <Redirect to="/sign-in" />; // Redirect to login page after logout
        }, 1000); // Simulating a 1 second delay
    };

    return (
        <>
            <Navbar expand={false} collapseOnSelect variant="light" className="d-md-none py-0 shadow-sm-custom" id="footer" fixed="bottom">
                <Nav style={{flexDirection: 'row'}}>
                    <NavItem link={Routes.DashboardOverview.path} icon={<FontAwesomeIcon icon={faHome}  />} title="Home" />
                    <NavItem className="text-center" icon={<FontAwesomeIcon icon={faCarAlt} />} link={Routes.UpcomingTours.path} title="New" />
                    <NavItem className="text-center" icon={<FontAwesomeIcon icon={faLocationArrow} />} link={Routes.ToursHistory.path} title="Past" />
                    <NavItem className="text-center" icon={<FontAwesomeIcon icon={faUserEdit} />} link={Routes.Profile.path} title="Account"/>
                    <NavItem className="text-center" icon={<FontAwesomeIcon icon={faWallet} />} link={Routes.Wallet.path} title="wallet"/>
                    {/* <NavItem className="text-center" icon={<IoSwapHorizontal />} link={Routes.Transaction.path} /> */}
                </Nav>
            </Navbar>
        </>
    );
};
